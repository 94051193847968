@font-face {
    font-family: 'Montserat-Bold';
    src: url(./font/MontserratAlternates-Bold.ttf);
}

@font-face {
    font-family: 'Montserat-Regular';
    src: url(./font/MontserratAlternates-Regular.ttf);
}


#root {
    background-color: #2D2F35;
    width: 100vw;
    height: 100vh;
    overflow:hidden;
    font-family: 'Montserat-Regular';
    color: #ffffff;

}

.content {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.menu-content {
    width: 100%;
    height: 100%;
    padding-top: 0px;
    padding-bottom: 50px;
}

.backgroundImg {
    background: url('../../public/images/backgroundimage.png') no-repeat;
    height: 90%;
    background-position: center;
    background-size: cover;
}

.login-body {
    width: 400px;
    height: 500px;
    margin-left: 5%;
    background-color: #2D2F35;
    padding: 1% 4%;
}

.icone-afone {
    width: 175px;
    height: auto;

}


.login-title {
    padding: 2% 2%;
    text-align: left;

}

.login-title h1 {
    font-family: 'Montserat-Bold';
    font-size: 25px;
    color: #ffffff;
    padding-top: 5px;
    margin: 0;

}

.login-title p {
    font-family: 'Montserat-Regular';
    font-size: 0.75em;
    color: #ffffff;
    padding: 0;
    margin: 0;

}

.field {
    padding-top: 3%;
}

.field label {
    font-family: 'Montserat-Bold';
    font-size: 80%;
    color: #ffffff;
    margin: 0;
    padding: 0.5em 0em;
}

.field input {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.75em 1em;
    font-size: 0.75em;
    color: #eee;
    font-family: 'Montserat-Regular';
    width: 100%;
}

.field input:hover,
.field input:focus {
    transition: 0.8s ease-in-out;
    border: 1px solid #C11E65;
    cursor: pointer;
}

.field button {
    border-radius: 150px;
    background: #C11E65;
    border: 0;
    width: 100%;
    color: #ffffff;
    padding: 0.5em 1em;
    margin: 2% 0%;
    font-family: 'Montserat-Bold';
    font-size: 1em;
}

.field a {
    text-decoration: none;
    border: 0;
    color: #ffffff;
    padding: 1em 0em;
    margin: 5% 0%;
    font-family: 'Montserat-Bold';
    font-size: 0.65em;
    float: right !important;
}

.field a:hover {
    transition: 0.8s ease-in-out;
    color: #C11E65;
}

.field button:hover {
    transition: 0.8s ease-in-out;
    background: #111113;

}

/* FOOTER */
.footer {
    width: 100%;
    height: 50%;
}

.pinkBorder {
    width: 100%;
    height: 8px;
    background: #E00C1F;
}

.purpleBorder {
    width: 100%;
    height: 30px;
    background: #681137;

}

.afone{
    padding-right: 30px;
}


.logoAfoneGroup {
    padding: 2em 10px;
    display: flex;
    vertical-align: middle;
}



.cardMenu {
    height: 270px;
    color: whitesmoke;
    background: #2d2f35;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 5px;
    overflow: visible;
}
.cardMenu img,svg {
    height: 100px;
}
#afone-logo {
    width: 180px;
    height: 30px;
}

.center {
    margin: auto;
    width: 75%;
    padding: 10px;
}

.cardMenu .item {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

}

.item:hover {
    transform: scale(0.95);
    transition: all 0.3s;
}

.item svg {
    width: 50%;
    height: 50%;
    margin-bottom: 7px;
}

.item--1 {
    background: #2d2f35;
}



.quantity {
    font-size: 25px;
    font-weight: 600;
}

.text {
    font-size: 12px;
    font-family: inherit;
    font-weight: 600;
}

.text--1 {
    color: rgba(149, 149, 255, 1);
}

.text--2 {
    color: rgba(252, 161, 71, 1);
}

.text--3 {
    color: rgba(66, 193, 110, 1);
}

.text--4 {
    color: #C11E65;
}



.main-menu {
    background: #2D2F35;
    margin: auto;
    width: 100%;
    height: 50px;
    padding: 10px;
}


.main-menu h3 {
    color: #ffffff;
    font-family: 'Montserat-Bold';
    font-size: 20px;
}

.main-menu p {
    color: #ffffff;
    font-family: 'Montserat-Regular';
    font-size: 15px;

}

.data-table {
    background-color: #2D2F35;
}

.clear-button {
    border: 1px solid #7B294D;

}

.tarification-numero {
    color: #ffffff;
    font-family: 'Montserat-Regular';

}

.tarification input {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.5em 0.5em;
    font-size: 14px;
    color: #eee;
    font-family: 'Montserat-Regular';
    width: 100%;
    height: 50px;
    border-right: none !important;
}

.form-control {
    color: #eee;

}

.tarification a {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.75em 1em;
    font-size: 0.75em;
    color: #eee;
    font-family: 'Montserat-Regular';
    width: 10%;
    border-left: none !important;
}

.tarification a:hover {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.75em 1em;
    font-size: 0.75em;
    width: 10%;
    border-left: none !important;
}


.tarification input::placeholder {
    color: #aaa !important;
}

.tarification input:active {
    color: #eeeeee !important;
    background: none !important;
    outline: 0 !important;
    border: 1px solid #7B294D;
    box-shadow: none;
}

.tarification svg {
    vertical-align: middle;
    background: none;
    color: #7B294D;
    margin: 5px;
    width: 20px;
    height: 20px;
}

.svg {
    border: 1px solid #7B294D;
    background: none;
}

.svg:hover {
    border: 1px solid #7B294D;
    background: none;

}

.modal-body {
    border: 1px solid #7B294D;
    background: #2D2F35;
    color: #ffffff;
    font-family: 'Montserat-Regular';

}

.modal .btn {
    border: 1px solid #7B294D;
    background: #2D2F35;
    color: #ffffff;

}

.btn-close {
    background: #E00C1F;
    color: #ffffff;

}

.btn-envoie {
    background: #C11E65;
    color: #ffffff;
    border: 1px solid #7B294D;
}

.table-bordered {
    background: none !important;
    outline: 0 !important;
    border: 1px solid #7B294D;

}

.table-bordered tr {
    color: #eeeeee !important;
    background: none !important;
    outline: 0 !important;
    border: 1px solid #7B294D;
    box-shadow: none;

}

.table-bordered th {
    color: #eeeeee !important;
    background: none !important;
    outline: 0 !important;
    border: 1px solid #7B294D;
    box-shadow: none;

}

.table-bordered td {
    color: #eeeeee !important;
    background: none !important;
    outline: 0 !important;
    border: 1px solid #7B294D;
    box-shadow: none;

}

.btn-tarif {
    margin: 5px;
    width: 100%;
}

.form-group label {
    color: #C11E65;
}

div.dataTables_wrapper div.dataTables_filter label {
    color: #C11E65;
    font-family: 'Montserat-Regular';
}

div.dataTables_wrapper div.dataTables_length label {
    color: #eee;
    font-family: 'Montserat-Regular';
}
div.dataTables_wrapper div.dataTables_info {
    color: #eee;
    font-family: 'Montserat-Regular';
}
.dataTables_paginate{
    color: #eee;
    font-family: 'Montserat-Regular';
}
.dataTables_paginate{
    color: #eee;
    font-family: 'Montserat-Regular';
}



/*HEADER*/
.header {
    width: 100%;
    position: relative;
    z-index: 9;
    top: 0px;
    background: #2D2F35;
    padding: 5px;
}

.header li {
    float: right;
    margin: 5px;
}

.header ul li {
    display: inline-block;
    padding: 1%;
    color: #ffffff;
}

.header ul li:hover {
    cursor: pointer;
}
.header img #afone-logo{
    width: 150px;
    height: 500px;
}
@media (max-width: 1280px) {
    .table-resp {
       
        max-width: 100%;
    }

}



/* /Responsive */
@media (max-width: 720px) and (max-height: 800px) {
    .login-body {
        width: 300px;
        height: 400px;
        margin-left: 5%;
        background-color: #2D2F35;
        padding: 1% 4%;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 100%;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 0.70em;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 10px;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.65em 1em;
        font-size: 10px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.50em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 3px 5px;
        margin: 3px 0px;
        font-family: 'Montserat-Bold';
        font-size: 15px;
    }

    .logoAfoneGroup {
        padding: 20px 10px;
        display: flex;
    }

    .afone{
        padding-right: 20px;
    }


    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        display: inline;
        height: 25px;
        padding-left: 15px;
        margin-bottom: 5px;
    }


    .header {
        width: 100%;
        position: relative;
        z-index: 9;
        top: 0px;
        background: #2D2F35;
        padding: 0px;
    }

    .header .row {
        display: flex;
        margin: 5px;
        left: 0%;
        display: flex;
    }

    .header ul {
        float: right;
        margin: 5px;
        right: 0px;
    }

    .header ul li {
        padding: 5px;
        color: #ffffff;
        list-style: none;
        font-size: 10px;
    }

    .header ul li:hover {
        cursor: pointer;
    }


    #menu-image {
        width: 25px;
        height: 25px;
    }


    #afone-logo {
        width: 130px;
        height: 30px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .main-menu {
        background: #2D2F35;
        margin: auto;
        width: 50%;
        padding: 0px;
    }

    .cardMenu {
        height: 250px;
        color: whitesmoke;
        background: #2d2f35;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 5px;
        overflow: visible;
    }
    .cardMenu img,svg {
        height: 100px;
    }
.tarification-numero {
    color: #ffffff;
    font-family: 'Montserat-Regular';

}

.tarification input {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.5em 0.5em;
    font-size: 14px;
    color: #eee;
    font-family: 'Montserat-Regular';
    width: 100%;
    height: 50px;
    border-right: none !important;
}

.form-control {
    color: #eee;

}

.tarification a {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.75em 1em;
    font-size: 0.75em;
    color: #eee;
    font-family: 'Montserat-Regular';
    width: 10%;
    border-left: none !important;
}

.tarification svg {
    vertical-align: middle;
    background: none;
    color: #7B294D;
    margin: 5px;
    width: 20px;
    height: 20px;
}

.tarification a:hover {
    background: none;
    outline: none;
    box-shadow: none;
    border: 1px solid #7B294D;
    border-radius: 150px;
    padding: 0.75em 1em;
    font-size: 0.75em;
    width: 10%;
    border-left: none !important;
}

.tarification input {
    color: #eeeeee;
    background: none;
    outline: none;
    font-size: 14px;
    height: 40px;

}

.tarification input::placeholder {
    color: #aaa !important;
}

.tarification input:active {
    color: #eeeeee !important;
    background: none !important;
    outline: 0 !important;
    border: 1px solid #7B294D;
    box-shadow: none;
}


}

@media (min-width: 720px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 900px) {
    .login-body {
        width: 400px;
        height: 500px;
        margin-left: 5%;
        background-color: #2D2F35;
        padding: 1% 4%;
    }

    .icone-afone {
        width: 185px;
        height: auto;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 25px;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 0.80em;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 15px;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.60em 0.8em;
        font-size: 15px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        height: 25px;
        display: inline;
        margin-top: 10px;
        margin-left: 13px;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.60em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 0.60em 0.8em;
        margin: 0px 0px;
        font-family: 'Montserat-Bold';
        font-size: 15px;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        display: inline;
        height: px;
        padding-left: 10px 25px;
    }


    .header {
        width: 100%;
        position: relative;
        z-index: 9;
        top: 0px;
        background: #2D2F35;
        padding: 0px;
    }

    .header .row {
        display: flex;
        margin: 5px;
        left: 0%;
        display: flex;
    }

    .header ul {
        float: right;
        margin: 5px;
        right: 0px;
    }

    .header ul li {
        padding: 5px;
        color: #ffffff;
        list-style: none;
        font-size: 10px;
    }

    .header ul li:hover {
        cursor: pointer;
    }


    #menu-image {
        width: 25px;
        height: 25px;
    }


    #afone-logo {
        width: 130px;
        height: 30px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .main-menu {
        background: #2D2F35;
        margin: auto;
        width: 50%;
        padding: 0px;
    }

    .cardMenu {
        height: 250px;
        color: whitesmoke;
        background: #2d2f35;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 5px;
        overflow: visible;
    }
    .cardMenu img,svg {
        height: 100px;
    }
    .tarification-numero {
        color: #ffffff;
        font-family: 'Montserat-Regular';

    }

    .tarification input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
        border-right: none !important;
    }

    .form-control {
        color: #eee;

    }

    .tarification a {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.4em 0.1em;
        color: #eee;
        font-family: 'Montserat-Regular';
        border-left: none !important;
    }

    .tarification a:hover {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        width: 10%;
        border-left: none !important;
    }

    .tarification input {
        color: #eeeeee;
        background: none;
        outline: none;
        font-size: 14px;
        height: 55px;

    }

    .tarification input::placeholder {
        color: #aaa !important;
    }

    .tarification input:active {
        color: #eeeeee !important;
        background: none !important;
        outline: 0 !important;
        border: 1px solid #7B294D;
        box-shadow: none;
    }
    .tarification svg {
        vertical-align: middle;
        background: none;
        color: #7B294D;
        margin: 5px;
        width: 20px;
        height: 20px;
    }

}


@media (min-width: 1024px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 1000px) {
    .icone-afone {
        width: 120px;
        height: auto;
    }

    .login-body {
        width: 300px;
        height: 400px;
        margin-left: 50px;
        background-color: #2D2F35;
        padding: 1% 2%;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 18px;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 0.70em;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 70%;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.65em 1em;
        font-size: 0.65em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.50em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 0.1em 0.5em;
        margin: 2px 0px;
        font-family: 'Montserat-Bold';
        font-size: 1em;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        height: 25px;
    }
    .header {
        width: 100%;
        position: relative;
        z-index: 9;
        top: 0px;
        background: #2D2F35;
        padding: 0px;
    }

    .header .row {
        display: flex;
        margin: 5px;
        left: 0%;
        display: flex;
    }

    .header ul {
        float: right;
        margin: 5px;
        right: 0px;
    }

    .header ul li {
        padding: 5px;
        color: #ffffff;
        list-style: none;
        font-size: 10px;
    }

    .header ul li:hover {
        cursor: pointer;
    }


    #menu-image {
        width: 25px;
        height: 25px;
    }


    #afone-logo {
        width: 130px;
        height: 30px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .main-menu {
        background: #2D2F35;
        margin: auto;
        width: 50%;
        padding: 0px;
    }


    .cardMenu {
        height: 250px;
        color: whitesmoke;
        background: #2d2f35;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 5px;
        overflow: visible;
    }
    .cardMenu img,svg {
        height: 100px;
    }
    #afone-logo {
        width: 180px;
        height: 30px;
    }
    
    .center {
        margin: auto;
        width: 75%;
        padding: 10px;
    }
    
    .cardMenu .item {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .item:hover {
        transform: scale(0.95);
        transition: all 0.3s;
    }
     
    .login-body {
        width: 400px;
        height: 500px;
        margin-left: 5%;
        background-color: #2D2F35;
        padding: 1% 4%;
    }

    .icone-afone {
        width: 185px;
        height: auto;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 25px;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 0.80em;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 15px;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.60em 0.8em;
        font-size: 15px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        height: 25px;
        display: inline;
        margin-top: 2px;
        margin-left: 20px;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.60em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 0.60em 0.8em;
        margin: 0px 0px;
        font-family: 'Montserat-Bold';
        font-size: 15px;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        display: inline;
        height: px;
        padding-left: 10px 25px;
    }
    .header {
        width: 100%;
        position: relative;
        z-index: 9;
        top: 0px;
        background: #2D2F35;
        padding: 0px;
    }

    .header .row {
        display: flex;
        margin: 5px;
        left: 0%;
        display: flex;
    }

    .header ul {
        float: right;
        margin: 5px;
        right: 0px;
    }

    .header ul li {
        padding: 5px;
        color: #ffffff;
        list-style: none;
        font-size: 10px;
    }

    .header ul li:hover {
        cursor: pointer;
    }


    #menu-image {
        width: 25px;
        height: 25px;
        margin-top: -4px;
    }


    #afone-logo {
        width: 130px;
        height: 30px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .main-menu {
        background: #2D2F35;
        margin: auto;
        width: 50%;
        padding: 0px;
    }

    .cardMenu {
        height: 250px;
        color: whitesmoke;
        background: #2d2f35;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 5px;
        overflow: visible;
    }
    .cardMenu img,svg {
        height: 100px;
    }
    .tarification-numero {
        color: #ffffff;
        font-family: 'Montserat-Regular';

    }


    .tarification input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.5em 0.5em;
        font-size: 14px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
        height: 50px;
        border-right: none !important;
    }

    .form-control {
        color: #eee;

    }

    .tarification a {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 10%;
        border-left: none !important;
    }

    .tarification svg {
        vertical-align: middle;
        background: none;
        color: #7B294D;
        margin: 5px;
        width: 20px;
        height: 20px;
    }


    .tarification a:hover {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        width: 10%;
        border-left: none !important;
    }

    .tarification input {
        color: #eeeeee;
        background: none;
        outline: none;
        font-size: 14px;
        height: 55px;

    }

    .tarification input::placeholder {
        color: #aaa !important;
    }

    .tarification input:active {
        color: #eeeeee !important;
        background: none !important;
        outline: 0 !important;
        border: 1px solid #7B294D;
        box-shadow: none;
    }


}


@media (min-width: 1280px) and (max-width: 1600px) and (min-height: 700px) and (max-height: 900px) {
    .icone-afone {
        width: 120px;
        height: auto;
    }

    .login-body {
        width: 300px;
        height: 400px;
        margin-left: 50px;
        background-color: #2D2F35;
        padding: 1% 2%;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 18px;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 0.70em;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 70%;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.65em 1em;
        font-size: 0.65em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.50em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 0.1em 0.5em;
        margin: 2px 0px;
        font-family: 'Montserat-Bold';
        font-size: 1em;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        height: 25px;
    }
    .header {
        width: 100%;
        position: relative;
        z-index: 9;
        top: 0px;
        background: #2D2F35;
        padding: 0px;
    }

    .header .row {
        display: flex;
        margin: 5px;
        left: 0%;
        display: flex;
    }

    .header ul {
        float: right;
        margin: 5px;
        right: 0px;
    }

    .header ul li {
        padding: 5px;
        color: #ffffff;
        list-style: none;
        font-size: 10px;
    }

    .header ul li:hover {
        cursor: pointer;
    }


    #menu-image {
        width: 25px;
        height: 25px;
    }


    #afone-logo {
        width: 130px;
        height: 30px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .main-menu {
        background: #2D2F35;
        margin: auto;
        width: 50%;
        padding: 0px;
    }

    .login-body {
        width: 400px;
        height: 500px;
        margin-left: 5%;
        background-color: #2D2F35;
        padding: 1% 4%;
    }

    .icone-afone {
        width: 185px;
        height: auto;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 25px;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 0.80em;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 15px;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.60em 0.8em;
        font-size: 15px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        height: 25px;
        display: inline;
        margin-top: 2px;
        margin-left: 20px;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.60em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 0.60em 0.8em;
        margin: 0px 0px;
        font-family: 'Montserat-Bold';
        font-size: 15px;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        display: inline;
        height: px;
        padding-left: 10px 25px;
    }
    .header {
        width: 100%;
        position: relative;
        z-index: 9;
        top: 0px;
        background: #2D2F35;
        padding: 0px;
    }

    .header .row {
        display: flex;
        margin: 5px;
        left: 0%;
        display: flex;
    }

    .header ul {
        float: right;
        margin: 5px;
        right: 0px;
    }

    .header ul li {
        padding: 5px;
        color: #ffffff;
        list-style: none;
        font-size: 10px;
    }

    .header ul li:hover {
        cursor: pointer;
    }


    #menu-image {
        width: 25px;
        height: 25px;
        margin-top: -4px;
    }


    #afone-logo {
        width: 130px;
        height: 30px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .main-menu {
        background: #2D2F35;
        margin: auto;
        width: 50%;
        padding: 0px;
    }


    .cardMenu {
        height: 250px;
        color: whitesmoke;
        background: #2d2f35;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 5px;
        overflow: visible;
    }
    .cardMenu img,svg {
        height: 100px;
    }
    #afone-logo {
        width: 180px;
        height: 30px;
    }
    
    .center {
        margin: auto;
        width: 75%;
        padding: 10px;
    }
    
    .cardMenu .item {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .item:hover {
        transform: scale(0.95);
        transition: all 0.3s;
    }
     
    .tarification-numero {
        color: #ffffff;
        font-family: 'Montserat-Regular';

    }


    .tarification input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.5em 0.5em;
        font-size: 14px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
        height: 50px;
        border-right: none !important;
    }

    .form-control {
        color: #eee;

    }

    .tarification a {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 10%;
        border-left: none !important;
    }

    .tarification svg {
        vertical-align: middle;
        background: none;
        color: #7B294D;
        margin: 5px;
        width: 20px;
        height: 20px;
    }

    .tarification a:hover {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        width: 10%;
        border-left: none !important;
    }

    .tarification input::placeholder {
        color: #aaa !important;
    }

    .tarification input:active {
        color: #eeeeee !important;
        background: none !important;
        outline: 0 !important;
        border: 1px solid #7B294D;
        box-shadow: none;
    }



}


@media (min-width: 1600px) and (max-width: 1920px) and (min-height: 900px) and (max-height: 1080px) {
        
    .login-body {
        width: 500px;
        height: 600px;
        margin-left: 5%;
        background-color: #2D2F35;
        padding: 1% 4%;
    }

    .icone-afone {
        width: 175px;
        height: auto;

    }


    .login-title {
        padding: 2% 2%;
        text-align: left;

    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 35px;
        color: #ffffff;
        padding-top: 5px;
        margin: 0;

    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 1em;
        color: #ffffff;
        padding: 0;
        margin: 0;

    }


    .field label {
        font-family: 'Montserat-Bold';
        font-size: 20px;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 1em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 4% 0%;
        font-family: 'Montserat-Bold';
        font-size: 0.80em;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 0.70em 1em;
        margin: 2% 0%;
        font-family: 'Montserat-Bold';
        font-size: 1em;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        display: inline;
        height: 35px;
        padding: 0px 20px;
    }


    .tarification input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.5em 0.5em;
        font-size: 14px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
        height: 50px;
        border-right: none !important;
    }

    .form-control {
        color: #eee;

    }

    .tarification a {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 10%;
        border-left: none !important;
    }

    .tarification svg {
        vertical-align: middle;
        background: none;
        color: #7B294D;
        margin: 5px;
        width: 20px;
        height: 20px;
    }


    .tarification a:hover {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        width: 10%;
        border-left: none !important;
    }


    .tarification input::placeholder {
        color: #aaa !important;
    }

    .tarification input:active {
        color: #eeeeee !important;
        background: none !important;
        outline: 0 !important;
        border: 1px solid #7B294D;
        box-shadow: none;
    }


}


@media (min-width: 1900px) and (min-width: 1080px){
    .login-body {
        width: 600px;
        height: 700px;
        margin-left: 5%;
        background-color: #2D2F35;
        padding: 20px 20px;
    }

    .icone-afone {
        width: 250px;
        height: auto;
    }

    .login-title h1 {
        font-family: 'Montserat-Bold';
        font-size: 30px;
        color: #ffffff;
        padding-top: 10px;
        margin: 0;
    }

    .login-title p {
        font-family: 'Montserat-Regular';
        font-size: 15px;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }

    .field label {
        font-family: 'Montserat-Bold';
        font-size: 20px;
        color: #ffffff;
        margin: 0;
        padding: 0.5em 0em;
    }

    .field input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 15px 20px;
        font-size: 1em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
    }

    .field a {
        text-decoration: none;
        border: 0;
        color: #ffffff;
        padding: 1em 0em;
        margin: 5% 0%;
        font-family: 'Montserat-Bold';
        font-size: 15px;
        float: right !important;
    }

    .field button {
        border-radius: 150px;
        background: #C11E65;
        border: 0;
        width: 100%;
        color: #ffffff;
        padding: 15px 20px;
        margin: 3px 0px;
        font-family: 'Montserat-Bold';
        font-size: 1em;
    }

    .logoAfoneGroup img,
    svg {
        vertical-align: middle;
        display: inline;
        height: 30px;
        padding: 0px 0px;
    }

    .tarification input {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.5em 0.5em;
        font-size: 14px;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 100%;
        height: 50px;
        border-right: none !important;
    }

    .form-control {
        color: #eee;

    }

    .tarification a {
        background: none;
        outline: none;
        box-shadow: none;
        border: 1px solid #7B294D;
        border-radius: 150px;
        padding: 0.75em 1em;
        font-size: 0.75em;
        color: #eee;
        font-family: 'Montserat-Regular';
        width: 10%;
        border-left: none !important;
    }

    .tarification svg {
        vertical-align: middle;
        background: none;
        color: #7B294D;
        margin: 5px;
        width: 20px;
        height: 20px;
    }


}